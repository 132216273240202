.root {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    min-width: 350px;

    .error {
      color: rgb(248, 121, 121);
      width: 100%;
    }
  }
}
