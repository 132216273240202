@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "Noto Sans Hebrew", sans-serif;
}

html {
  min-height: 100%;
}
body,
main {
  min-height: 100vh;
  width: 100%;
}

main {
  padding: 16px 8px;
  width: 90%;
  margin: auto;
}
.jodit-container {
  text-align: right;
}

.jodit_theme_summer {
  --jd-color-background-default: #417505;
  --jd-color-border: #474025;
  --jd-color-panel: #5fd3a2;
  --jd-color-icon: #8b572a;
}
