.root {
  .stats {
    width: 100%;
    display: flex;

    gap: 24px;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
}
