.root {
  background-color: rgb(39, 39, 42);
  padding: 8px 12px;
  border-radius: 12px;

  display: flex;
  flex-direction: column;

  gap: 4px;

  flex-grow: 1;

  p {
    font-size: 12px;
    line-height: 20px;
    color: rgb(212, 212, 216);
  }
}
