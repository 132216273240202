.root {
  .actions {
    width: 100%;
    padding: 16px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;

      gap: 16px;
      .input {
        width: 400px;
        max-width: 90%;
      }

      .dropdown {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .dropdownLabel {
          font-size: 12px;
        }
      }
    }
  }
}
