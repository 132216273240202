.root {
  display: flex;
  flex-direction: row;
  gap: 32px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
  .left {
    display: flex;
    flex-direction: column;
    gap: 8px;

    flex-grow: 1;

    .details {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .row {
        display: flex;
        flex-direction: row;
        gap: 8px;

        @media (max-width: 900px) {
          flex-direction: column;
        }

        .input {
          flex-grow: 1;
        }
      }
    }
  }
  .right {
    min-width: 300px;
    display: flex;

    flex-direction: column;
    align-items: stretch;

    justify-content: flex-start;

    gap: 24px;

    .actions {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .row {
        display: flex;
        gap: 8px;
      }
    }
  }
}
