.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .card {
    width: 100%;
    max-width: 540px;

    .footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 16px;
    }
  }
}
