.root {
  .actions {
    width: 100%;
    padding: 16px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .input {
      max-width: 400px;
    }
  }
}
