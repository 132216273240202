.root {
  flex-grow: 1;

  p {
    text-align: center;
    width: 100%;
  }
  h2 {
    font-size: 48px;
    text-align: center;
  }
}
